import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div style={{backgroundColor: '#fff', padding: '2em'}}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist. Go to <Link to="/" style={{color: '#666'}}> Zinip Home</Link>.</p>
    </div>
  </Layout>
)

export default NotFoundPage
